import _ from 'lodash';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import {
  HostCreditEntityProfileStepOne,
  StepFormHeader,
  ViewEditCTAPair
} from '@/components/atomic/atoms';
import { HostCreditEntityProfileStepTwo } from '@/components/atomic/molecules';
import {
  hostCreditEntityProfileStepFieldErrorConfig,
  hostCreditEntityProfileTabs
} from '@/services/hostCreditEntity.service';

const HostProfileHeader = ({
  fieldsErrorConfig,
  errors,
  formActiveStepId,
  onExitClick,
  setFormActiveStepId,
  showCloseIcon
}) => (
  <StepFormHeader
    {...{
      fieldsErrorConfig,
      errors,
      formActiveStepId,
      onExitClick,
      setFormActiveStepId,
      showCloseIcon,
      tabList: Object.values(hostCreditEntityProfileTabs)
    }}
  />
);

const ViewEditHostCreditEntityProfileOrganism = ({
  hostCreditEntityProfileDetails: { hostCreditEntity },
  opsUserList,
  showToast
}) => {
  const router = useRouter();
  const [isEditable, setIsEditable] = useState(false);
  const [formActiveStepId, setFormActiveStepId] = useState(
    Object.values(hostCreditEntityProfileTabs)[0].id
  );

  const { getValues, clearErrors, control, register, reset, setValue } =
    useForm({
      values: hostCreditEntity
    });

  const { errors } = useFormState({
    control
  });

  const onExitClick = () => {
    router.back();
  };

  const onSubmitHandler = () => {};

  const notificationConfig = {
    errors,
    toastConfig: {
      ...showToast,
      show: showToast.show || !_.isEmpty(errors)
    }
  };

  return (
    <div className='flex-1 px-5 md:ml-[30rem] pl-10 md:pr-10 relative'>
      <div
        id={hostCreditEntityProfileTabs.PROFILE.id}
        className='bg-white mb-7 px-6 pb-10'
      >
        <HostProfileHeader
          {...{
            errors,
            fieldsErrorConfig: hostCreditEntityProfileStepFieldErrorConfig,
            formActiveStepId,
            onExitClick,
            setFormActiveStepId,
            showCloseIcon: !isEditable
          }}
        />
        <HostCreditEntityProfileStepOne
          {...{
            clearErrors,
            errors,
            formActiveStepId,
            getValues,
            isEditable,
            opsUserList,
            register,
            setValue
          }}
        />

        <HostCreditEntityProfileStepTwo />
        {
          // TODO: CREDIT_REQUEST_STATUS Enum to manage status based class in config/hostCreditEntity.js
        }
        <ViewEditCTAPair
          {...{
            backward: {
              onClick: () => {
                if (isEditable) {
                  setIsEditable(false);
                  clearErrors();
                  reset();
                } else {
                  onExitClick();
                }
              }
            },
            forward: {
              onClick: onSubmitHandler
            },
            notificationConfig,
            type: isEditable ? 'editCTAPair' : 'viewCTAPair'
          }}
        />
      </div>
    </div>
  );
};

export default ViewEditHostCreditEntityProfileOrganism;

import {
  ApprovedCreditRequest,
  PendingCreditRequest
} from '@/components/atomic/atoms';

const HostCreditEntityProfileStepTwo = () => (
  <div className='flex flex-col gap-4'>
    <PendingCreditRequest />
    <ApprovedCreditRequest />
  </div>
);

export default HostCreditEntityProfileStepTwo;
